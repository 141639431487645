import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from './language.service';

@Pipe({
  name: 'language',
})
export class LanguagePipe implements PipeTransform {
  constructor(private language: LanguageService) {}

  transform(value: string, args?: string | number | (number | string)[]): any {
    return value && this.language.get(value, args);
  }
}
