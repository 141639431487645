import { BudgetConfigInterface } from '../interfaces/budget-config.interface';

export const BUDGET_CONFIG: BudgetConfigInterface[] = [
  {
    flow: 'drive-traffic',
    suggestedValues: [{ value: 5 }, { value: 15, default: true }, { value: 25 }, { value: 50 }],
  },
  {
    flow: 'shopping',
    suggestedValues: [{ value: 5 }, { value: 25, default: true }, { value: 50 }, { value: 100 }],
  },
  {
    flow: 'search',
    suggestedValues: [{ value: 5 }, { value: 25, default: true }, { value: 50 }, { value: 100 }],
  },
  {
    flow: 'facebook',
    suggestedValues: [{ value: 5 }, { value: 25, default: true }, { value: 50 }, { value: 100 }],
  },
];
