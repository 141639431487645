import { Environment } from './environment.config';

export const environment: Environment = {
  hmr: false,
  name: 'staging',
  customer: 'https://customer.staging-cobiro.com',
  app: 'https://app.staging-cobiro.com',
  api: 'https://api.staging-cobiro.com/api',
  hub: 'https://hub.staging-cobiro.com',
  gateway: 'https://cas.staging-cobiro.com/api',
  google: {
    tagManagerId: 'GTM-KL2WMGK',
    gapiClientId: '282098614690-7lbp4245gmn4ceqjgnf4pngfoiq0q0ao.apps.googleusercontent.com',
    geocode: 'AIzaSyBj8z7eriJEGi_YPNKWExEy8z-mpdtvU3E',
    maps: 'AIzaSyBj8z7eriJEGi_YPNKWExEy8z-mpdtvU3E',
    redirectUri: '/redirect/google',
    googleFonts: 'AIzaSyCVJT6Z9zyDpDy72tCBfl-nZOMvcVvidJw',
  },
  shopify: {
    api_key: 'de9a315f3d9a9d571e6f0512118d9a7d',
  },
  facebook: {
    appId: '725061394563139',
    cobiroApi: 'https://hub.staging-cobiro.com/v1/facebook-plain',
  },
  sentry: 'https://f183f4c100e84b1d89d95e3f4380d363@sentry.io/2852385',
  rendera: 'https://rendera-cdn.cobiro.com',
  wixApp: 'https://wix.staging-cobiro.com',
};
