<header class="header">
  <ng-container *ngTemplateOutlet="sideBar"></ng-container>
</header>

<section class="content">
  <ng-container *ngTemplateOutlet="sideBar"></ng-container>

  <div class="content-wrap">
    <ng-content></ng-content>
  </div>
</section>

<ng-template #sideBar>
  <cbr-side-bar
    *ngIf="routeData?.hasNav && routeData?.menuPointState && routeData?.subMenuPointState"
    [currentMenuPoint]="currentMenuPoint"
    [currentSubMenuPoint]="currentSubMenuPoint"
  ></cbr-side-bar>
</ng-template>
