import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DatastoreConfig, JsonApiDatastore, JsonApiDatastoreConfig } from 'cbr-jsonapi';
import { environment } from 'src/environments/environment';

import { Category } from './models/category.model';
import {
  GoogleSearchBudget,
  GoogleSearchCallExtension,
  GoogleSearchCampaignGroup,
  GoogleSearchLocation,
} from './models/google-search.model';
import { Keyword, KeywordGroup } from './models/keyword-group.model';
import { MarketingPlan, Phase, Step } from './models/marketing-plan.model';
import { Product } from './models/product.model';
import { Site } from './models/site.model';
import { Login, Register, User } from './models/user.model';
import { Variant } from './models/variant.model';

const config: DatastoreConfig = {
  baseUrl: environment.hub,
  models: {
    login: Login,
    register: Register,
    sites: Site,
    users: User,
    marketingPlan: MarketingPlan,
    phases: Phase,
    steps: Step,
    googleSearchCampaignGroup: GoogleSearchCampaignGroup,
    googleSeachBudget: GoogleSearchBudget,
    googleSearchLocation: GoogleSearchLocation,
    googleSearchCallExtensions: GoogleSearchCallExtension,
    keywordGroup: KeywordGroup,
    keywords: Keyword,
    products: Product,
    variants: Variant,
    categories: Category,
  },
};

@Injectable()
@JsonApiDatastoreConfig(config)
export class DatastoreService extends JsonApiDatastore {
  constructor(http: HttpClient) {
    super(http);
  }
}
