import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { AuthService } from '@cbr/dark-matter';

@Injectable()
export class UnauthenticatedInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private router: Router, private authService: AuthService) {}

  /**
   * Implements HttpInterceptor to handle 401 error.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(error => {
        if (
          error &&
          (error.status === 401 ||
            (error.errors && error.errors.length && error.errors[0].status === '401') ||
            (error.error && error.error.length && error.error[0].status === '401')) &&
          !request.url.includes('login') &&
          !request.url.includes('logout')
        ) {
          if (request.url.endsWith('refresh')) {
            this.router.navigate(['user', 'logout']);
          } else {
            if (!this.refreshTokenInProgress) {
              this.refresh();
            }
            return this.refreshTokenSubject.pipe(
              filter(result => result != null),
              take(1),
              switchMap((token: string) =>
                next.handle(this.addAuthenticationToken(request, token)),
              ),
            );
          }
        }
        return throwError(error);
      }),
    );
  }

  /**
   * Handles token refresh
   */
  private refresh() {
    this.refreshTokenInProgress = true;
    this.refreshTokenSubject.next(null);
    this.authService.refreshToken().subscribe(
      res => {
        if (res) {
          this.refreshTokenSubject.next(res.access_token);
          this.refreshTokenInProgress = false;
        } else {
          this.refreshTokenInProgress = false;
          this.router.navigate(['user', 'logout']);
        }
      },
      error => {
        this.refreshTokenInProgress = false;
        this.router.navigate(['user', 'logout']);
      },
    );
  }

  private addAuthenticationToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    const headers = request.headers.set('Authorization', `Bearer ${token}`);
    return request.clone({ headers: headers });
  }
}
