<!--Desktop-->
<div class="desktop">
  <header>
    <div>
      <img class="side-bar-image" *ngIf="siteObj.screenshotUri" [src]="siteImageUrl" />
    </div>

    <div class="side-bar-image-title">
      <h4>{{ siteObj.title }}</h4>
    </div>
  </header>
  <section class="sub-menu-points">
    <a
      class="sub-menu-point"
      *ngFor="let subMenuPoint of currentMenuPoint?.subMenuPoints; trackBy: trackByFn"
      [class.active]="subMenuPoint.state === currentSubMenuPoint.state"
      (click)="onSubMenuPointClicked(subMenuPoint)"
    >
      <cbr-svg class="icon" [icon]="subMenuPoint.iconSrc" fill="#92A0AF" size="20"></cbr-svg>
      {{ subMenuPoint.title | language }}
    </a>
  </section>
</div>
<!--Mobile-->
<div class="mobile">
  <section class="sub-menu-points">
    <a
      class="sub-menu-point"
      *ngFor="let subMenuPoint of currentMenuPoint?.subMenuPoints; trackBy: trackByFn"
      [class.active]="subMenuPoint.state === currentSubMenuPoint.state"
      (click)="onSubMenuPointClicked(subMenuPoint)"
    >
      <div>
        <div class="text">{{ subMenuPoint.title | language }}</div>
        <div class="underline"></div>
      </div>
    </a>
  </section>
</div>
