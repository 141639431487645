import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';

export interface SentryErrorInterface {
  title: string;
  message?: string;
  tags?: { title: string; message: string }[];
}

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  /**
   * Set the current user on session init
   * @param user
   */
  assignUser(user: { id: string; email: string }): void {
    Sentry.configureScope(scope => {
      scope.setUser({
        id: user?.id,
      });
    });
  }
}
