import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ButtonModule, SnackbarItemModule } from '@cobiro/primitives';
import { LanguageModule } from '@cbr/dark-matter';
import { AdblockModalComponent } from './adblock-modal/adblock-modal.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SnackbarComponent } from './snackbar/snackbar.component';

@NgModule({
  declarations: [
    AdblockModalComponent,
    NotFoundComponent,
    SnackbarComponent
  ],
  imports: [CommonModule, RouterModule, ButtonModule, SnackbarItemModule, LanguageModule],
  exports: [NotFoundComponent, SnackbarComponent],
  entryComponents: [AdblockModalComponent],
})
export class GeneralModule {}
