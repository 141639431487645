import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { api } from './api.config';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiUtilsService {
  constructor(private http: HttpClient) {}

  /**
   * Warns in console of an error while making an http request
   * Returns an observable for an error thrown
   * @param errorResponse Error response from http request
   * @returns Observable of the error
   */
  errorHandler(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError({ ...errorResponse.error, status: errorResponse.status });
  }

  /**
   * Generates headers for an http request with content language, type, and passed headers
   * @param headersObject Headers to which to append standard headers
   * @returns Headers needed for an http request
   */
  getHeaders(headersObject: Object, method): HttpHeaders {
    let languageHeader = {};
    if (method !== this.http.get) {
      languageHeader = {
        'Content-Language': 'en',
        'Content-Type': 'application/json',
      };
    }
    const headers = Object.assign(languageHeader, headersObject);
    let httpHeaders = new HttpHeaders();
    Object.keys(headers).forEach(header => {
      httpHeaders = httpHeaders.append(header, headers[header]);
    });
    return httpHeaders;
  }

  /**
   * Transforms given parameters to a full url to make an api request
   * @param url Url that needs to be transformed. E.g. 'site/:id'
   * @param options Values that need to be substituted in the url. E.g: { id: 123 }
   * @param versionNumber Number that indicates a version different than the default one
   * @returns Full url for the passed api endpoint
   */
  transformUrl(url: string, options: Object = {}, versionNumber?: number): string {
    const regex = new RegExp(/:\w+/g);
    const matches = url.match(regex);
    const host = environment.api;
    if (!matches) {
      return `${host}/` + api.version + `/${url}`;
    }
    matches.forEach(match => {
      if (options.hasOwnProperty(match.substr(1))) {
        url = url.replace(match, options[match.substr(1)]);
      }
    });
    const version = versionNumber ? `v${versionNumber}` : api.version;
    return `${host}/` + version + `/${url}`;
  }

  /**
   * Use this to make an api call, wraps the call with standard code
   * @param method Selected request method (GET, POST, PUT...)
   * @param params Parameters for the request: url, options and body
   * @returns The response from the api as a promise
   */
  wrap(
    method: (...args) => {},
    params: { url: string; options?: { [key: string]: any }; body?: any },
  ): Promise<any> {
    if (!params.options) {
      params.options = {};
    }
    let args: any[] = [params.url];
    params.options.headers = this.getHeaders(params.options.headers, method);
    args = params.body ? args.concat([params.body, params.options]) : args.concat(params.options);
    return method
      .apply(this.http, args)
      .pipe(
        map(response => {
          return response || [];
        }),
        catchError(this.errorHandler.bind(this)),
      )
      .toPromise();
  }
}
