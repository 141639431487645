export const SUGGESTED_BUDGET = [
  {
    currency_code: 'AED',
    options_5: 20,
    options_10: 40,
    options_15: 55,
    options_25: 90,
    options_50: 180,
    options_100: 370,
    options_200: 740,
    min_budget: 7,
  },
  {
    currency_code: 'ARS',
    options_5: 210,
    options_10: 420,
    options_15: 640,
    options_25: 1000,
    options_50: 2000,
    options_100: 4000,
    options_200: 8500,
    min_budget: 85,
  },
  {
    currency_code: 'AUD',
    options_5: 8,
    options_10: 15,
    options_15: 20,
    options_25: 35,
    options_50: 70,
    options_100: 140,
    options_200: 290,
    min_budget: 3,
  },
  {
    currency_code: 'BGN',
    options_5: 10,
    options_10: 20,
    options_15: 30,
    options_25: 45,
    options_50: 90,
    options_100: 180,
    options_200: 350,
    min_budget: 3,
  },
  {
    currency_code: 'BND',
    options_5: 8,
    options_10: 15,
    options_15: 20,
    options_25: 35,
    options_50: 70,
    options_100: 140,
    options_200: 270,
    min_budget: 3,
  },
  {
    currency_code: 'BOB',
    options_5: 35,
    options_10: 70,
    options_15: 100,
    options_25: 170,
    options_50: 350,
    options_100: 690,
    options_200: 1500,
    min_budget: 15,
  },
  {
    currency_code: 'BRL',
    options_5: 20,
    options_10: 40,
    options_15: 60,
    options_25: 95,
    options_50: 190,
    options_100: 380,
    options_200: 750,
    min_budget: 7,
  },
  {
    currency_code: 'CAD',
    options_5: 8,
    options_10: 15,
    options_15: 20,
    options_25: 35,
    options_50: 65,
    options_100: 130,
    options_200: 260,
    min_budget: 3,
  },
  {
    currency_code: 'CHF',
    options_5: 6,
    options_10: 10,
    options_15: 15,
    options_25: 25,
    options_50: 50,
    options_100: 100,
    options_200: 200,
    min_budget: 2,
  },
  {
    currency_code: 'CLP',
    options_5: 3500,
    options_10: 7000,
    options_15: 10000,
    options_25: 17000,
    options_50: 34000,
    options_100: 69000,
    options_200: 140000,
    min_budget: 1400,
  },
  {
    currency_code: 'CNY',
    options_5: 35,
    options_10: 70,
    options_15: 100,
    options_25: 170,
    options_50: 350,
    options_100: 690,
    options_200: 1500,
    min_budget: 15,
  },
  {
    currency_code: 'COP',
    options_5: 16000,
    options_10: 32000,
    options_15: 48000,
    options_25: 80000,
    options_50: 160000,
    options_100: 320000,
    options_200: 640000,
    min_budget: 6400,
  },
  {
    currency_code: 'CZK',
    options_5: 110,
    options_10: 230,
    options_15: 340,
    options_25: 570,
    options_50: 1000,
    options_100: 2500,
    options_200: 4500,
    min_budget: 45,
  },
  {
    currency_code: 'DKK',
    options_5: 35,
    options_10: 70,
    options_15: 100,
    options_25: 170,
    options_50: 330,
    options_100: 670,
    options_200: 1500,
    min_budget: 15,
  },
  {
    currency_code: 'EGP',
    options_5: 85,
    options_10: 170,
    options_15: 250,
    options_25: 420,
    options_50: 830,
    options_100: 1500,
    options_200: 3500,
    min_budget: 35,
  },
  {
    currency_code: 'EUR',
    options_5: 6,
    options_10: 10,
    options_15: 15,
    options_25: 25,
    options_50: 45,
    options_100: 90,
    options_200: 180,
    min_budget: 2,
  },
  {
    currency_code: 'FJD',
    options_5: 10,
    options_10: 20,
    options_15: 30,
    options_25: 55,
    options_50: 110,
    options_100: 210,
    options_200: 430,
    min_budget: 4,
  },
  {
    currency_code: 'GBP',
    options_5: 6,
    options_10: 10,
    options_15: 15,
    options_25: 20,
    options_50: 40,
    options_100: 80,
    options_200: 160,
    min_budget: 2,
  },
  {
    currency_code: 'HKD',
    options_5: 40,
    options_10: 80,
    options_15: 120,
    options_25: 200,
    options_50: 390,
    options_100: 780,
    options_200: 1500,
    min_budget: 15,
  },
  {
    currency_code: 'HRK',
    options_5: 35,
    options_10: 65,
    options_15: 100,
    options_25: 170,
    options_50: 330,
    options_100: 660,
    options_200: 1500,
    min_budget: 15,
  },
  {
    currency_code: 'HUF',
    options_5: 1500,
    options_10: 3000,
    options_15: 4500,
    options_25: 7000,
    options_50: 14000,
    options_100: 29000,
    options_200: 58000,
    min_budget: 580,
  },
  {
    currency_code: 'IDR',
    options_5: 70000,
    options_10: 140000,
    options_15: 210000,
    options_25: 350000,
    options_50: 700000,
    options_100: 1400000,
    options_200: 2800000,
    min_budget: 28000,
  },
  {
    currency_code: 'ILS',
    options_5: 20,
    options_10: 35,
    options_15: 55,
    options_25: 90,
    options_50: 180,
    options_100: 350,
    options_200: 710,
    min_budget: 7,
  },
  {
    currency_code: 'INR',
    options_5: 350,
    options_10: 690,
    options_15: 1000,
    options_25: 1500,
    options_50: 3500,
    options_100: 7000,
    options_200: 14000,
    min_budget: 140,
  },
  {
    currency_code: 'JOD',
    options_5: 4,
    options_10: 8,
    options_15: 10,
    options_25: 20,
    options_50: 35,
    options_100: 70,
    options_200: 140,
    min_budget: 1,
  },
  {
    currency_code: 'JPY',
    options_5: 540,
    options_10: 1000,
    options_15: 1500,
    options_25: 2500,
    options_50: 5500,
    options_100: 11000,
    options_200: 22000,
    min_budget: 220,
  },
  {
    currency_code: 'KES',
    options_5: 520,
    options_10: 1000,
    options_15: 1500,
    options_25: 2500,
    options_50: 5000,
    options_100: 10000,
    options_200: 21000,
    min_budget: 210,
  },
  {
    currency_code: 'KRW',
    options_5: 6000,
    options_10: 12000,
    options_15: 18000,
    options_25: 29000,
    options_50: 59000,
    options_100: 120000,
    options_200: 240000,
    min_budget: 2400,
  },
  {
    currency_code: 'LKR',
    options_5: 880,
    options_10: 2000,
    options_15: 2500,
    options_25: 4500,
    options_50: 9000,
    options_100: 18000,
    options_200: 35000,
    min_budget: 360,
  },
  {
    currency_code: 'MAD',
    options_5: 50,
    options_10: 100,
    options_15: 150,
    options_25: 240,
    options_50: 480,
    options_100: 960,
    options_200: 2000,
    min_budget: 20,
  },
  {
    currency_code: 'MXN',
    options_5: 95,
    options_10: 190,
    options_15: 290,
    options_25: 480,
    options_50: 950,
    options_100: 2000,
    options_200: 4000,
    min_budget: 40,
  },
  {
    currency_code: 'MYR',
    options_5: 20,
    options_10: 40,
    options_15: 60,
    options_25: 100,
    options_50: 210,
    options_100: 410,
    options_200: 820,
    min_budget: 8,
  },
  {
    currency_code: 'NGN',
    options_5: 2000,
    options_10: 3500,
    options_15: 5500,
    options_25: 9000,
    options_50: 18000,
    options_100: 36000,
    options_200: 72000,
    min_budget: 730,
  },
  {
    currency_code: 'NOK',
    options_5: 45,
    options_10: 85,
    options_15: 130,
    options_25: 220,
    options_50: 430,
    options_100: 860,
    options_200: 1500,
    min_budget: 15,
  },
  {
    currency_code: 'NZD',
    options_5: 8,
    options_10: 15,
    options_15: 25,
    options_25: 40,
    options_50: 75,
    options_100: 150,
    options_200: 300,
    min_budget: 3,
  },
  {
    currency_code: 'PEN',
    options_5: 20,
    options_10: 35,
    options_15: 50,
    options_25: 85,
    options_50: 170,
    options_100: 330,
    options_200: 660,
    min_budget: 7,
  },
  {
    currency_code: 'PHP',
    options_5: 260,
    options_10: 510,
    options_15: 770,
    options_25: 1500,
    options_50: 2500,
    options_100: 5000,
    options_200: 10000,
    min_budget: 110,
  },
  {
    currency_code: 'PKR',
    options_5: 800,
    options_10: 1500,
    options_15: 2500,
    options_25: 4000,
    options_50: 8000,
    options_100: 16000,
    options_200: 32000,
    min_budget: 330,
  },
  {
    currency_code: 'PLN',
    options_5: 20,
    options_10: 40,
    options_15: 60,
    options_25: 95,
    options_50: 190,
    options_100: 380,
    options_200: 760,
    min_budget: 8,
  },
  {
    currency_code: 'RON',
    options_5: 20,
    options_10: 45,
    options_15: 65,
    options_25: 110,
    options_50: 210,
    options_100: 420,
    options_200: 840,
    min_budget: 8,
  },
  {
    currency_code: 'RSD',
    options_5: 530,
    options_10: 1000,
    options_15: 1500,
    options_25: 2500,
    options_50: 5500,
    options_100: 10000,
    options_200: 21000,
    min_budget: 210,
  },
  {
    currency_code: 'RUB',
    options_5: 320,
    options_10: 630,
    options_15: 950,
    options_25: 1500,
    options_50: 3000,
    options_100: 6500,
    options_200: 13000,
    min_budget: 130,
  },
  {
    currency_code: 'SAR',
    options_5: 20,
    options_10: 40,
    options_15: 60,
    options_25: 95,
    options_50: 190,
    options_100: 380,
    options_200: 750,
    min_budget: 8,
  },
  {
    currency_code: 'SEK',
    options_5: 50,
    options_10: 95,
    options_15: 140,
    options_25: 240,
    options_50: 470,
    options_100: 940,
    options_200: 2000,
    min_budget: 20,
  },
  {
    currency_code: 'SGD',
    options_5: 8,
    options_10: 15,
    options_15: 20,
    options_25: 35,
    options_50: 70,
    options_100: 140,
    options_200: 270,
    min_budget: 3,
  },
  {
    currency_code: 'THB',
    options_5: 160,
    options_10: 310,
    options_15: 460,
    options_25: 770,
    options_50: 1500,
    options_100: 3000,
    options_200: 6000,
    min_budget: 60,
  },
  {
    currency_code: 'TND',
    options_5: 15,
    options_10: 30,
    options_15: 45,
    options_25: 70,
    options_50: 140,
    options_100: 290,
    options_200: 570,
    min_budget: 6,
  },
  {
    currency_code: 'TRY',
    options_5: 30,
    options_10: 60,
    options_15: 85,
    options_25: 140,
    options_50: 280,
    options_100: 570,
    options_200: 1000,
    min_budget: 10,
  },
  {
    currency_code: 'TWD',
    options_5: 160,
    options_10: 310,
    options_15: 470,
    options_25: 780,
    options_50: 1500,
    options_100: 3000,
    options_200: 6000,
    min_budget: 60,
  },
  {
    currency_code: 'UAH',
    options_5: 130,
    options_10: 260,
    options_15: 390,
    options_25: 650,
    options_50: 1500,
    options_100: 2500,
    options_200: 5000,
    min_budget: 50,
  },
  {
    currency_code: 'USD',
    options_5: 6,
    options_10: 10,
    options_15: 15,
    options_25: 25,
    options_50: 50,
    options_100: 100,
    options_200: 200,
    min_budget: 2,
  },
  {
    currency_code: 'UYU',
    options_5: 180,
    options_10: 350,
    options_15: 530,
    options_25: 880,
    options_50: 2000,
    options_100: 3500,
    options_200: 7000,
    min_budget: 70,
  },
  {
    currency_code: 'VND',
    options_5: 120000,
    options_10: 230000,
    options_15: 350000,
    options_25: 580000,
    options_50: 1200000,
    options_100: 2300000,
    options_200: 4600000,
    min_budget: 47000,
  },
  {
    currency_code: 'ZAR',
    options_5: 70,
    options_10: 140,
    options_15: 210,
    options_25: 350,
    options_50: 700,
    options_100: 1500,
    options_200: 3000,
    min_budget: 30,
  },
];
