import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { pick } from 'lodash';
import * as jwtDecode from 'jwt-decode';
import { DatastoreService, Login, RefreshToken } from '@cbr/datastore';
import { LocalStorageInterface } from '../../interfaces/local-storage.interface';
import { LocalStorageService } from '../storage/local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authStorage: LocalStorageInterface;
  customerStorage: LocalStorageInterface;

  constructor(
    private localStorage: LocalStorageService,
    private dataStoreService: DatastoreService,
  ) {
    this.authStorage = this.localStorage.init('auth');
    this.customerStorage = this.localStorage.init('customer');
  }

  login(loginData): Observable<Login> {
    return this.dataStoreService
      .createRecord(Login, loginData)
      .save()
      .pipe(
        tap(res => {
          this.customerStorage.set(pick(res, ['id', 'email', 'first_name']));
          this.setAuthStorage(res.access_token, res.refresh_token, res.token_type);
        }),
      );
  }

  refreshToken(): Observable<RefreshToken> {
    const authData = this.authStorage.get();
    if (authData && authData.expires_at * 1000 < Date.now()) {
      return this.dataStoreService
        .createRecord(RefreshToken, {
          refresh_token: authData.refresh_token,
        })
        .save()
        .pipe(
          tap((res: RefreshToken) => {
            this.setAuthStorage(res.access_token, res.refresh_token, res.token_type);
          }),
        );
    } else {
      return throwError('Token is not expired');
    }
  }

  /**
   * Sets authentication data to local storage
   * expires_at is being decoded from the token
   * @param accessToken - Authentication token
   * @param refreshToken - Refresh token
   * @param tokenType - type (Bearer)
   */
  private setAuthStorage(accessToken: string, refreshToken: string, tokenType: string) {
    this.authStorage.set({
      access_token: accessToken,
      refresh_token: refreshToken,
      token_type: tokenType,
      expires_at: jwtDecode(accessToken).exp,
    });
  }
}
