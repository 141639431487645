import { Attribute, JsonApiModel, JsonApiModelConfig } from 'cbr-jsonapi';

@JsonApiModelConfig({
  type: 'ads',
  modelEndpointUrl: '',
  apiVersion: 'v1',
})
export class GoogleSearchAdModel extends JsonApiModel {
  @Attribute({ serializedName: 'headline_1' }) headline1: string;
  @Attribute({ serializedName: 'headline_2' }) headline2: string;
  @Attribute({ serializedName: 'headline_3' }) headline3: string;
  @Attribute({ serializedName: 'description_1' }) description1: string;
  @Attribute({ serializedName: 'description_2' }) description2: string;
  @Attribute({ serializedName: 'path_1' }) path1: string;
  @Attribute({ serializedName: 'path_2' }) path2: string;
  @Attribute({ serializedName: 'ad_group' }) adGroup: number;
  @Attribute({ serializedName: 'google_id' }) googleId: number;
  @Attribute() status: string;
  @Attribute({ serializedName: 'approval_status' }) approvalStatus: string;
  @Attribute({ serializedName: 'disapproval_reasons' }) disapprovalReasons: string[];
  @Attribute({ serializedName: 'final_urls' }) finalUrls: string[];
  @Attribute({ serializedName: 'created_at' }) createdAt: Date;
  @Attribute({ serializedName: 'updated_at' }) updatedAt: Date;
}
