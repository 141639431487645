import { Attribute, JsonApiModel, JsonApiModelConfig, HasMany, BelongsTo } from 'cbr-jsonapi';

@JsonApiModelConfig({
  type: 'plans',
  apiVersion: 'v1',
  modelEndpointUrl: 'marketing-plans',
})
export class MarketingPlan extends JsonApiModel {
  @Attribute() site: number;
  @Attribute() goal: string;
  @Attribute() country: { id: number; country: string; city: string; reach: number };
  @Attribute({ serializedName: 'currency_code' }) currencyCode: string;
  @Attribute() budget: number; // <- cannot be used right now // TODO define type
  @Attribute() step: string;
  @Attribute() event: string;
  @Attribute() status: string;
  @HasMany() phases: Phase[];
}

@JsonApiModelConfig({
  type: 'phases',
  apiVersion: 'v1',
})
export class Phase extends JsonApiModel {
  @Attribute() identifier: string;
  @BelongsTo() marketingPlan: MarketingPlan;
  @HasMany() steps: Step[];
}

@JsonApiModelConfig({
  type: 'steps',
  apiVersion: 'v1',
})
export class Step extends JsonApiModel {
  @Attribute() identifier: string;
  @Attribute() status: string;
  @Attribute() order: number;
  @BelongsTo() phase: Phase;
}

@JsonApiModelConfig({
  type: 'events',
  apiVersion: 'v1',
})
export class MarketingPlanEvent extends JsonApiModel {
  @Attribute() identifier: string;
  @Attribute({ serializedName: 'start_date' }) startDate: Date;
  @Attribute({ serializedName: 'end_date' }) endDate: Date;
  @Attribute({ serializedName: 'event_type' }) eventType: string;
  @Attribute() status: string;
}
