import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivationStart, Router, RouterEvent } from '@angular/router';
import { cloneDeep } from 'lodash';
import { filter } from 'rxjs/operators';
import { MenuPointInterface } from './interfaces/menu-point.interface';
import { menuPointConfig } from './configs/menu-point.config';
import { Subscription } from 'rxjs';
import { SubMenuPointInterface } from './interfaces/sub-menu-point.interface';
import { ConsoleService } from '@cbr/dark-matter';

interface RouteDataInterface {
  hasNav: boolean;
  menuPointState: string;
  subMenuPointState: string;
}

@Component({
  selector: 'cbr-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  private routerSubscription: Subscription;

  routeData: RouteDataInterface;

  currentMenuPoint: MenuPointInterface;
  currentSubMenuPoint: SubMenuPointInterface;

  constructor(private router: Router, private consoleService: ConsoleService) {}

  ngOnInit() {
    this.listenActivationEnds();
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  /**
   * We're listing on each router change event and subscribe to it to either remove or add navigation
   */
  private listenActivationEnds() {
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof ActivationStart))
      .subscribe((event: RouterEvent) => {
        const newEvent: any = cloneDeep(event);

        // save routeData to component
        this.routeData = newEvent && newEvent.snapshot && newEvent.snapshot.data;

        // calculate currentMenuPoint and currentSubMenuPoint
        if (this.routeData.menuPointState && this.routeData.subMenuPointState) {
          this.currentMenuPoint = this.findCurrentMenuPoint();
          this.currentSubMenuPoint = this.findCurrentSubMenuPoint();
        }
      });
  }
  /**
   * @return Current menuPoint based on routeData and menuPointConfig
   */
  private findCurrentMenuPoint() {
    return menuPointConfig.find(
      (menuPoint: MenuPointInterface) => menuPoint.state === this.routeData.menuPointState,
    );
  }

  /**
   * @return Current subMenuPoint based on routeData and currentMenuPoint
   */
  private findCurrentSubMenuPoint() {
    return this.currentMenuPoint.subMenuPoints.find(
      (subMenuPoint: SubMenuPointInterface) =>
        subMenuPoint.state === this.routeData.subMenuPointState,
    );
  }
}
