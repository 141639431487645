import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { ENVIRONMENT_NAMES } from '../../config/environment-names.config';

@Injectable()
export class StorageService {
  memoryStorage: any;

  constructor(private cookies: CookieService) {
    // Base storage obj for memory storage
    this.memoryStorage = {};
  }

  /**
   * Sets a cookie with given details
   * @param key Key of the cookie that needs to be set
   * @param content Content of the cookie that needs to be set
   * @param expiration Amount of days or date in which the token will expire, default is 365 days
   */
  set(key: string, content: string, expiration: number | Date = 365) {
    this.get(key) && this.delete(key);
    this.cookies.set(
      this.environmentKey(key),
      content,
      expiration,
      '/',
      location.hostname,
      location.protocol === 'https:',
      'Lax',
    );
  }

  /**
   * Looks for a cookie with given key and returns it
   * @param key Key of the cookie that needs to be fetched
   * @returns cookie content or null
   */
  get(key: string): string {
    return this.cookies.get(this.environmentKey(key)) || null;
  }

  /**
   * Deletes a cookie with given key
   * @param key Key of the cookie to be deleted
   */
  delete(key: string) {
    this.cookies.delete(this.environmentKey(key), '/', window.location.hostname);
  }

  /**
   * Prepends the environment name to the cookie key
   * @param name key of the cookie that needs to be modified
   */
  private environmentKey(name: string): string {
    if (environment.name !== ENVIRONMENT_NAMES.production) {
      return `${environment.name}_${name}`;
    }
    return name;
  }

  /**
   *
   *
   *
   * CODE COPIED FROM SIMON'S PR, UPDATE THIS ASAP
   *
   *
   *
   */
  // Takes in data, figures out if its an object/array and stringify it to save in localstorage
  // If its a string it just sets it to localstorage as is with the key as is
  // NOTE - I have to use type any otherwise it vomits and say it has to be a string even though im modifing it to string later on
  setData(key: string, data: any) {
    let dataString: string = '';

    if (data !== null && typeof data === 'object') {
      dataString = JSON.stringify(data);
    } else {
      dataString = data;
    }

    localStorage.setItem(this.environmentKey(key), dataString);
  }

  // Get a dataString from localstorage by key
  getData(key: string) {
    let dataString: any = localStorage.getItem(this.environmentKey(key));
    if (!dataString) {
      return null;
    }

    let isArray = dataString && dataString[0] === '[' && dataString[dataString.length - 1] === ']';
    let isObject = dataString && dataString[0] === '{' && dataString[dataString.length - 1] === '}';

    // Handle all types
    if (isArray || isObject) {
      return JSON.parse(dataString);
    } else if (dataString === null + '') {
      return null;
    } else if (dataString === undefined + '') {
      return undefined;
    } else if (dataString === Infinity + '') {
      return Infinity;
    } else if (!isNaN(dataString)) {
      return parseInt(dataString);
    } else {
      return dataString;
    }
  }

  // Delete item in localstorage by key
  removeData(key: string) {
    localStorage.removeItem(this.environmentKey(key));
  }

  // Deletes multiple items in localstorage by an array of keys
  removeMultipleData(keys: string[]) {
    keys.forEach(key => {
      this.removeData(this.environmentKey(key));
    });
  }

  /**
   * Set memory based storage
   * @param key string that defines name of where to save
   * @param data can be any type of data that you want to save in memory
   */
  setMemory(key: string, data: any) {
    this.memoryStorage[key] = data;
  }

  /**
   * Get memory based storage
   * @param key string that defines name of where to get data from
   */
  getMemory(key: string) {
    return this.memoryStorage[key];
  }

  /**
   * Remove memory based storage
   * @param key string that defines name of where to remove data from
   */
  removeMemory(key: string) {
    delete this.memoryStorage[key];
  }

  setCampaignData(adId: number, adData: any) {
    let data = this.getData('cbr_data');
    if (!data) {
      this.setData('cbr_data', [{ id: adId, data: adData }]);
      return;
    }

    let selected = data.find(ad => ad?.id === adId);
    if (selected) {
      selected.data = { ...selected.data, ...adData };
      this.setData('cbr_data', data);
      return;
    }
    data.push({ id: adId, data: adData });
    this.setData('cbr_data', data);
  }

  getCampaignData(adId: number): any {
    const data = this.getData('cbr_data');
    if (data) {
      const selected = data.find(ad => ad?.id === adId);
      if (selected && selected.data) {
        return selected.data;
      }
    }
    return false;
  }

  removeCampaignData(adId: number, property?: string) {
    const data = this.getData('cbr_data');

    if (!data) {
      return;
    }

    if (!property) {
      const updatedData = data.filter(ad => ad?.id !== adId);
      this.setData('cbr_data', updatedData);
      return;
    }

    const selected = data.find(ad => ad?.id === adId);
    if (selected && selected.data) {
      delete selected.data[property];
      this.setData('cbr_data', data);
    }
  }
}
