import { MenuPointInterface } from '../interfaces/menu-point.interface';

export const menuPointConfig: MenuPointInterface[] = [
  {
    state: 'site',
    route: '/site/:siteId',
    subMenuPoints: [
      {
        title: '_dashboard',
        iconSrc: 'dashboard',
        route: '/site/:siteId/dashboard',
        state: 'dashboard',
      },
      {
        title: '_marketing_plan',
        iconSrc: 'checklist',
        route: '/site/:siteId/plan',
        state: 'plan',
      },
      {
        title: '_my_ads',
        iconSrc: 'online',
        route: '/site/:siteId/activities',
        state: 'activities',
      },
      {
        title: '_store',
        iconSrc: 'marketplace',
        route: '/site/:siteId/store',
        state: 'store',
      },
    ],
  },
  {
    state: 'settings',
    route: '/settings',
    subMenuPoints: [
      {
        title: '_profile',
        iconSrc: 'avatar',
        route: '/settings/profile',
        state: 'profile',
      },
      // {
      //   title: '_password',
      //   iconSrc: 'locked',
      //   route: '/settings/password',
      //   state: 'password',
      // },
      // {
      //   title: '_accounts',
      //   iconSrc: 'link',
      //   route: '/settings/account',
      //   state: 'accounts',
      // },
      {
        title: '_notifications',
        iconSrc: 'notification',
        route: '/settings/notification',
        state: 'notifications',
      },
    ],
  },
];
