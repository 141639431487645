import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root',
})
export class LoadingResolver implements Resolve<any> {
  /**
   * Allows to activate the route if the sites can be fetched and if the current site is activated
   * This guard assumes that the Authenticated guard has been called
   */

  constructor(private loadingService: LoadingService) {}

  resolve(): any {
    this.loadingService.loading.next(false);
    return true;
  }
}
