import { Injectable } from '@angular/core';
import { SideBarMetaInterface } from '../interfaces/side-bar-meta.interface';
import { ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SideBarService {
  readonly sideBarMeta$: ReplaySubject<SideBarMetaInterface>;

  constructor() {
    this.sideBarMeta$ = new ReplaySubject();
  }
}
