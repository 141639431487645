import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ENVIRONMENT_NAMES } from '../../config/environment-names.config';
import { SentryErrorHandler } from '../../error-handlers/sentry-error-handler.service';

@Injectable({ providedIn: 'root' })
export class ConsoleService {
  constructor(private sentryErrorHandler: SentryErrorHandler) {}

  /**
   * Warns in the console based on the environment
   * Logs to Sentry if it's on production
   * @param message Message that you wish to log
   * @param object Object that needs to be logged after the message
   */
  warn(message: string, object?: any) {
    if (environment.name !== ENVIRONMENT_NAMES.production) {
      console.warn(message);
      object !== undefined && console.log(object);
    } else {
      this.sentryErrorHandler.logErrorMessageToSentry(
        `${message} --- ${this.extractMessageFormErrorResponse(object)}`,
      );
    }
  }

  /**
   * Logs in the console based on the environment
   * @param message Message that you wish to log
   * @param object Object that needs to be logged after the message
   */
  log(message: string, object?: any) {
    if (environment.name !== ENVIRONMENT_NAMES.production) {
      console.log(message);
      object !== undefined && console.log(object);
    }
  }

  /**
   * Attempts to match error object to known error formats and returns it in user friendly text
   * @param error - Any error abject
   */
  private extractMessageFormErrorResponse(error: any): string {
    let status = '';
    if (error) {
      let message = error;
      if (error.errors && error.errors.length) {
        message = error.errors[0];
        if (error.errors[0].status) {
          status = error.errors[0].status;
        }
      } else if (error.error) {
        message = error.error;
      }

      if (error.status) {
        status = error.status;
      }

      if (status) {
        status = `Status: ${status}`;
      }

      return `${JSON.stringify(message)} ${status}`;
    }
    return '';
  }
}
