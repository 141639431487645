import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';

import { LocalStorageInterface, LocalStorageService } from '@cbr/dark-matter';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedGuard implements CanActivate {
  authStorage: LocalStorageInterface;
  customerStorage: LocalStorageInterface;

  constructor(private localStorageService: LocalStorageService, private router: Router) {
    this.authStorage = this.localStorageService.init('auth');
    this.customerStorage = this.localStorageService.init('customer');
  }

  /**
   * Checks if a user is authenticated and allows to access the activated route,
   * otherwise redirects users to '/user/login'
   */
  canActivate(): Observable<boolean | UrlTree> {
    const authObj = this.authStorage.get();

    if (authObj && authObj.access_token) {
      return of(true);
    } else {
      return of(this.router.createUrlTree(['/user/login']));
    }
  }
}
