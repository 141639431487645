import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './general/not-found/not-found.component';
import { AuthenticatedGuard } from './general/guards/authenticated/authenticated.guard';
import { IsAdminGuard } from './cas/guards/is-admin.guard';

export const routingConfiguration: any = {
  paramsInheritanceStrategy: 'always',
};

const routes: Routes = [
  {
    path: 'logout',
    redirectTo: '/user/logout',
  },
  {
    path: 'login',
    redirectTo: '/user/login',
  },
  {
    path: 'signup',
    redirectTo: '/user/signup',
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
  },
  {
    path: '',
    canActivate: [AuthenticatedGuard],
    children: [
      {
        path: '',
        canActivate: [IsAdminGuard],
        loadChildren: () => import('./cas/cas.module').then(m => m.CasModule),
        data: {
          hasNav: false,
        },
      },
    ],
  },
  {
    path: 'not-found',
    data: {
      hasNav: true,
    },
    component: NotFoundComponent,
  },
  {
    path: '**',
    redirectTo: 'not-found',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
