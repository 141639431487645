import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { environment } from 'src/environments/environment';

import { ConsoleService, LocalStorageService, LocalStorageInterface } from '@cbr/dark-matter';
import { Site } from '@cbr/datastore';

import { MenuPointInterface } from '../interfaces/menu-point.interface';
import { SideBarMetaInterface } from '../interfaces/side-bar-meta.interface';
import { SideBarService } from './side-bar.service';
import { SubMenuPointInterface } from '../interfaces/sub-menu-point.interface';

@Component({
  selector: 'cbr-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit, OnDestroy {
  @Input() currentMenuPoint: MenuPointInterface;
  @Input() currentSubMenuPoint: SubMenuPointInterface;

  sideBarMeta: SideBarMetaInterface;
  siteStorage: LocalStorageInterface;

  siteObj: Site;

  siteImageUrl: string;

  private sideBarMetaSubscription: Subscription;

  constructor(
    private consoleService: ConsoleService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private sideBarService: SideBarService,
  ) {
    this.siteStorage = this.localStorageService.init('site');
  }

  ngOnInit() {
    this.siteObj = this.siteStorage.get();

    if (this.siteObj.screenshotUri) {
      this.siteImageUrl = `${environment.rendera}${this.siteObj.screenshotUri}`;
    }

    this.sideBarMetaSubscription = this.sideBarService.sideBarMeta$.subscribe(
      (sideBarMeta: SideBarMetaInterface) => (this.sideBarMeta = sideBarMeta),
    );
  }

  ngOnDestroy() {
    if (this.sideBarMetaSubscription) {
      this.sideBarMetaSubscription.unsubscribe();
    }
  }

  /**
   * Eventlistenser for when the topbarmenu is clicked, to track it in Mixpanel
   * @param subMenuPoint menu element clicked
   */
  onSubMenuPointClicked(subMenuPoint: SubMenuPointInterface) {
    this.router.navigate([this.replaceIds(subMenuPoint.route)]);
  }

  trackByFn(index: any) {
    return index;
  }

  private replaceIds(route: string): string {
    const siteObj = this.siteStorage.get();

    if (siteObj && siteObj.id) {
      route = route.replace(':siteId', siteObj.id.toString());
    } else {
      this.consoleService.warn('No siteId was in the site object');
    }

    return route;
  }
}
