import { Attribute, JsonApiModel, JsonApiModelConfig } from 'cbr-jsonapi';

@JsonApiModelConfig({
  type: 'locations',
  apiVersion: 'v1',
  modelEndpointUrl: 'google-locations',
})
export class Location extends JsonApiModel {
  @Attribute({ serializedName: 'target_type' }) targetType: string;
  @Attribute({ serializedName: 'country_code' }) countryCode: string;
  @Attribute({ serializedName: 'canonical_name' }) canonicalName: string;
  @Attribute() name: string;
  @Attribute() status: string;
  @Attribute() reach: number;
  @Attribute({ serializedName: 'created_at' }) createdAt: Date;
  @Attribute({ serializedName: 'updated_at' }) updatedAt: Date;
}
