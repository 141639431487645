import { APP_BASE_HREF, DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorHandler, NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MAT_RIPPLE_GLOBAL_OPTIONS, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { DatastoreService } from '@cbr/datastore';
import { TokenInterceptor, UnauthenticatedInterceptor } from '@cbr/interceptors';
import {
  SentryErrorHandler,
  LocalStorageService,
  ENVIRONMENT_NAMES,
  LanguageModule,
  StorageService,
} from '@cbr/dark-matter';
import { SvgLoaderModule } from '@cobiro/primitives';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CasUserDeleteCoreModule } from './cas/cas-user/cas-user-delete/cas-user-delete.core.module';
import { GETS_TOKENS } from './cas/cas-user/cas-user-delete/domain/gets-tokens';
import { HttpUserGatewayService } from './cas/cas-user/cas-user-delete/infrastructure/http-user-gateway.service';
import { GeneralModule } from './general/general.module';
import { LayoutModule } from './layout/layout.module';
import { Languages } from '../assets/languages/languages';
import { NgSelectModule } from '@ng-select/ng-select';

export function getErrorHandler(localStorageService: LocalStorageService): ErrorHandler {
  if (environment.name !== ENVIRONMENT_NAMES.local) {
    return new SentryErrorHandler(localStorageService);
  }
  return new ErrorHandler();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AgmCoreModule.forRoot({ apiKey: environment.google.maps }),
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    GeneralModule,
    HttpClientModule,
    MatDatepickerModule,
    MatDialogModule,
    MatNativeDateModule,
    OverlayModule,
    PortalModule,
    LanguageModule,
    LayoutModule,
    SvgLoaderModule,
    CasUserDeleteCoreModule,
    // Ng select does not support IVY lazy-loading, remove this line when that happens. ng-select #1464
    NgSelectModule,
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
    {
      provide: ErrorHandler,
      useFactory: getErrorHandler,
      deps: [LocalStorageService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthenticatedInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    DatePipe,
    DatastoreService,
    StorageService,
    {
      provide: 'Languages',
      useValue: Languages,
    },
    { provide: Window, useValue: window },
    { provide: GETS_TOKENS, useClass: HttpUserGatewayService }, // when not provide in app.module gives an 'No provider for injection token'
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
