import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { CasUserDeleteState } from './application/cas-user-delete.state';
import { DELETES_USER } from './domain/deletes-user';
import { HttpUserDeleteService } from './infrastructure/http-user-delete.service';

@NgModule({
  imports: [MatDialogModule],
  providers: [CasUserDeleteState, { provide: DELETES_USER, useClass: HttpUserDeleteService }],
})
export class CasUserDeleteCoreModule {}
