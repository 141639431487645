import { Attribute, JsonApiModel, JsonApiModelConfig } from 'cbr-jsonapi';

@JsonApiModelConfig({
  type: 'sites',
  apiVersion: 'v1',
})
export class Site extends JsonApiModel {
  @Attribute() title: string;
  @Attribute() domain: string;
  @Attribute() user: number;
  @Attribute() activated: boolean;
  @Attribute({ serializedName: 'created_at' }) createdAt: Date;
  @Attribute({ serializedName: 'updated_at' }) updatedAt: Date;
  @Attribute({ serializedName: 'country_code' }) countryCode: string;
  @Attribute({ serializedName: 'language_code' }) languageCode: string;
  @Attribute({ serializedName: 'screenshot_uri' }) screenshotUri: string;
  @Attribute() location: {
    lat: number;
    lon: number;
    city: string;
    state: string;
    country: string;
    default: boolean;
    currency: string;
    iso_code: string;
    timezone: string;
    continent: string;
    state_name: string;
    postal_code: string;
  };
}

@JsonApiModelConfig({
  type: 'site-info',
  apiVersion: 'v1',
  modelEndpointUrl: 'shop-integrations/info',
})
export class SiteInfo extends JsonApiModel {
  @Attribute() domain: string;
  @Attribute() technologies: { name: string; last_detected: Date }[];
}
