import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

import { DELETES_USER, DeletesUser } from '../domain/deletes-user';

@Injectable()
export class CasUserDeleteState {
  private readonly _loadingSubject = new BehaviorSubject(false);

  loading$: Observable<boolean> = this._loadingSubject.asObservable();
  userDeleted$: Subject<any> = new Subject();

  constructor(@Inject(DELETES_USER) private _deletesUser: DeletesUser, private _router: Router) {}

  delete(id: string): void {
    this._loadingSubject.next(true);
    this._deletesUser
      .delete(id)
      .pipe(
        take(1),
        finalize(() => this._loadingSubject.next(false)),
      )
      .subscribe(success => {
        if (success) {
          this._handleNavigation();
          this.userDeleted$.next();
        }
      });
  }

  private _handleNavigation(): void {
    this._router.navigate(['/users']);
  }
}
