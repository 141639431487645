import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from 'src/environments/environment';
import { LocalStorageInterface } from '../interfaces/local-storage.interface';
import { LocalStorageService } from '../services/storage/local-storage/local-storage.service';
const { version: appVersion } = require('package.json');

@Injectable({ providedIn: 'root' })
export class SentryErrorHandler implements ErrorHandler {
  private customerStorage: LocalStorageInterface;

  /**
   * Integrations config is a fix for double logging in Angular:
   * https://github.com/getsentry/sentry-javascript/issues/2169#issuecomment-525722319
   * @param localStorage - LocalStorageService instance
   */
  constructor(private localStorage: LocalStorageService) {
    Sentry.init({
      release: `${appVersion}`,
      environment: environment.name,
      dsn: environment.sentry,
      integrations(integrations) {
        return integrations.filter(i => i.name !== 'TryCatch');
      },
    });
    this.customerStorage = this.localStorage.init('customer');
  }

  /**
   * Handle captured exceptions form the application:
   * - If it's a chunk loading error - refresh the page, because that means that the user is running an old version
   * - check if it's a JSON API format error and if it is, print tite and details
   * - try to handle any other type of error
   * @param error - captures exception
   */
  handleError(error) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    } else {
      const id = this.getUserId();
      Sentry.configureScope(scope => scope.setUser({ id: id }));

      if (error.errors && error.errors.length) {
        error.errors.forEach(e => this.logErrorMessageToSentry(`${e.title} - ${e.detail}`));
      } else {
        Sentry.captureException(error.originalError || error.error || error);
      }
      throw error;
    }
  }

  /**
   * Set the user ID and send an error message to Sentry
   * @param message - Error message
   */
  logErrorMessageToSentry(message: string) {
    const id = this.getUserId();
    Sentry.configureScope(scope => scope.setUser({ id: id }));
    Sentry.captureException(new Error(message));
  }

  /**
   * Get the user ID from local storage, and empty string if it's not stored yet
   */
  private getUserId(): string {
    return this.customerStorage && this.customerStorage.get() ? this.customerStorage.get().id : '';
  }
}
