import { JsonApiModelConfig, JsonApiModel, Attribute } from 'cbr-jsonapi';

/**
 * Get, update, delete a user by id.
 * To get the user by token, use the current user model.
 */
@JsonApiModelConfig({
  type: 'users',
  apiVersion: 'v1',
})
export class User extends JsonApiModel {
  @Attribute() email: string;
  @Attribute() password: string;
  @Attribute() group: string;
  @Attribute() source: string;
  @Attribute({ serializedName: 'utm_interest' }) utmInterest: string;
  @Attribute({ serializedName: 'is_verified' }) isVerified: boolean;
  @Attribute({ serializedName: 'partner_id' }) partnerId: string;
  @Attribute({ serializedName: 'first_name' }) firstName: string;
  @Attribute({ serializedName: 'last_name' }) lastName: string;
  @Attribute({ serializedName: 'weekly_summary' }) weeklySummary: boolean;
  @Attribute({ serializedName: 'created_at' }) createdAt: Date;
  @Attribute({ serializedName: 'updated_at' }) updatedAt: Date;
}

/**
 * Only available method for this model is get.
 * Use this model to retreive the user assigned to the token.
 */
@JsonApiModelConfig({
  type: 'users',
  apiVersion: 'v1',
  modelEndpointUrl: 'me',
})
export class CurrentUser extends User {}

/**
 * Only available method for this model is post.
 * Use this model to create a user.
 */
@JsonApiModelConfig({
  type: 'users',
  apiVersion: 'v1',
  modelEndpointUrl: 'register',
})
export class Register extends User {}

@JsonApiModelConfig({
  type: 'password-reset',
  apiVersion: 'v1',
  modelEndpointUrl: 'password-reset',
})
export class PasswordReset extends User {}

@JsonApiModelConfig({
  type: 'password-reset-change',
  apiVersion: 'v1',
  modelEndpointUrl: 'password-reset-change',
})
export class PasswordResetChange extends JsonApiModel {
  @Attribute() token: string;
  @Attribute() email: string;
  @Attribute() password: string;
  @Attribute() password_confirmation: string;
}

/**
 * Only available method for this model is post.
 * Use this model to log a user in.
 */
@JsonApiModelConfig({
  type: 'login',
  apiVersion: 'v1',
  modelEndpointUrl: 'login',
})
export class Login extends User {}

/**
 * Only available method for this model is post.
 * Use this model to log a user in.
 */
@JsonApiModelConfig({
  type: 'logout',
  apiVersion: 'v1',
  modelEndpointUrl: 'logout',
})
export class Logout extends User {}

@JsonApiModelConfig({
  type: 'users',
  apiVersion: 'v1',
  modelEndpointUrl: 'users/email/verify/resend',
})
export class ResendEmailVerification extends JsonApiModel {
  @Attribute() email: string;
}

@JsonApiModelConfig({
  type: 'login',
  apiVersion: 'v1',
  modelEndpointUrl: 'refresh',
})
export class RefreshToken extends JsonApiModel {
  @Attribute() access_token: string;
  @Attribute() refresh_token: string;
  @Attribute() expires_in: string;
  @Attribute() token_type: string;
}
