import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgModule, DropdownModule } from '@cobiro/primitives';
import { LanguageModule } from '@cbr/dark-matter';
import { GeneralModule } from '../general/general.module';
import { LayoutComponent } from './layout.component';
import { LayoutRoutingModule } from './layout-routing.module';
import { SideBarComponent } from './side-bar/side-bar.component';

@NgModule({
  declarations: [LayoutComponent, SideBarComponent],
  imports: [
    CommonModule,
    GeneralModule,
    LayoutRoutingModule,
    SvgModule,
    DropdownModule,
    LanguageModule,
  ],
  exports: [LayoutComponent],
})
export class LayoutModule {}
