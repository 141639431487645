import { Attribute, HasMany, JsonApiModel, JsonApiModelConfig } from 'cbr-jsonapi';
import { Variant } from './variant.model';

@JsonApiModelConfig({
  type: 'products',
  apiVersion: 'v1',
})
export class Product extends JsonApiModel {
  @Attribute({ serializedName: 'site_id' }) siteId: number;
  @Attribute({ serializedName: 'canonical_url' }) canonicalUrl: string;
  @Attribute({ serializedName: 'image_url' }) imageUrl: string;
  @Attribute({ serializedName: 'is_adult_only' }) isAdultOnly: boolean;
  @Attribute() title: string;
  @Attribute() language: string;
  @Attribute() content: string;
  @Attribute() brand: string;
  @Attribute({ serializedName: 'created_at' }) createdAt: Date;
  @Attribute({ serializedName: 'updated_at' }) updatedAt: Date;
  @HasMany() variants: Variant[];
}
