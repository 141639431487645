import { Attribute, JsonApiModel, JsonApiModelConfig, BelongsTo, HasMany } from 'cbr-jsonapi';

@JsonApiModelConfig({
  type: 'campaign-groups',
  modelEndpointUrl: 'google-search/campaign-groups',
  apiVersion: 'v1',
})
export class GoogleSearchCampaignGroup extends JsonApiModel {
  @Attribute({ serializedName: 'site_id' }) siteId: number;
  @Attribute() budget: string;
  @Attribute() status: string;
  @Attribute() name: string;
  @Attribute({ serializedName: 'google_accounts_ownership' }) googleAccountsOwnershipId: number;
  @Attribute({ serializedName: 'max_cpc' }) maxCpc: string;
  @Attribute({ serializedName: 'started_advertising_at' }) startedAdvertisingAt: Date;
  @Attribute({ serializedName: 'created_at' }) createdAt: Date;
  @Attribute({ serializedName: 'updated_at' }) updatedAt: Date;
  @HasMany({ serializedName: 'call_extensions' }) callExtensions: GoogleSearchCallExtension[];
}

@JsonApiModelConfig({
  type: 'campaign-reports',
  modelEndpointUrl: 'google-search/campaign-groups',
  apiVersion: 'v1',
})
export class GoogleSearchCampaignReport extends JsonApiModel {
  @Attribute() clicks: number;
  @Attribute() cost: number;
  @Attribute({ serializedName: 'ctr' }) click_through_rate: number;
  @Attribute() date: string;
  @Attribute() impressions: number;
  @Attribute() micro_cost: number;
}

@JsonApiModelConfig({
  type: 'budgets',
  modelEndpointUrl: 'google-search/budgets',
  apiVersion: 'v1',
})
export class GoogleSearchBudget extends JsonApiModel {
  @Attribute() name: string;
  @Attribute() amount: number;
  @Attribute() currency: string;
  @Attribute({ serializedName: 'google_id' }) googleId: number;
}

@JsonApiModelConfig({
  type: 'locations',
  modelEndpointUrl: 'google-search/locations',
  apiVersion: 'v1',
})
export class GoogleSearchLocation extends JsonApiModel {
  @Attribute({ serializedName: 'google_id' }) googleId: number;
  @Attribute({ serializedName: 'original_id' }) originalId: number;
  @Attribute() latitude: number;
  @Attribute() longitude: number;
  @Attribute() radius: number;
  @Attribute() units: string;
  @Attribute({ serializedName: 'created_at' }) createdAt: string;
  @Attribute({ serializedName: 'updated_at' }) updatedAt: string;
  @BelongsTo() campaign: GoogleSearchCampaignGroup;
}

@JsonApiModelConfig({
  type: 'cobiro-keywords',
  modelEndpointUrl: 'google-search/cobiro-keywords',
  apiVersion: 'v1',
})
export class CobiroKeywords extends JsonApiModel {
  @Attribute({ serializedName: 'campaign_group' }) campaignGroup: string;
  @Attribute() groups: { id: number; keywords: number[] }[];
}

@JsonApiModelConfig({
  type: 'cobiro-ads',
  modelEndpointUrl: 'google-search/cobiro-ads',
  apiVersion: 'v1',
})
export class CobiroAds extends JsonApiModel {
  @Attribute({ serializedName: 'campaign_group' }) campaignGroup: string;
  @Attribute({ serializedName: 'ad_id' }) adId: string;
}

@JsonApiModelConfig({
  type: 'call-extensions',
  modelEndpointUrl: 'google-search/extension-calls',
  apiVersion: 'v1',
})
export class GoogleSearchCallExtension extends JsonApiModel {
  @Attribute({ serializedName: 'country_code' }) countryCode: string;
  @Attribute({ serializedName: 'phone_number' }) phoneNumber: string;
  @Attribute({ serializedName: 'call_tracking' }) callTracking: boolean;
  @Attribute({ serializedName: 'created_at' }) createdAt: Date;
  @Attribute({ serializedName: 'updated_at' }) updatedAt: Date;
  @HasMany() 'campaign-groups': GoogleSearchCampaignGroup;
}

@JsonApiModelConfig({
  type: 'ads',
  modelEndpointUrl: 'google-search/ads',
  apiVersion: 'v1',
})
export class GoogleSearchAds extends JsonApiModel {
  @Attribute({ serializedName: 'ad_group' }) adGroup: number;
  @Attribute({ serializedName: 'headline_1' }) headline1: string;
  @Attribute({ serializedName: 'headline_2' }) headline2: string;
  @Attribute({ serializedName: 'headline_3' }) headline3: string;
  @Attribute({ serializedName: 'description_1' }) description1: string;
  @Attribute({ serializedName: 'descrition_2' }) description2: string;
  @Attribute({ serializedName: 'path_1' }) path1: string;
  @Attribute({ serializedName: 'path_2' }) path2: string;
  @Attribute({ serializedName: 'final_urls' }) finalUrls: string[];
  @Attribute({ serializedName: 'status' }) status: boolean;
}
