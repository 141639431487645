import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { InfoNotification } from '../../interfaces/info-notification.interface';
import { LanguageService } from '../../pipes/language/language.service';

interface errorKeysInterface {
  [key: string]: string[];
}

interface Banner {
  button?: { title: string; link: string };
  links?: { reason: string; link: string }[];
  description?: string;
}

@Injectable({
  providedIn: 'root',
})
export class InfoService {
  banners: InfoNotification[];
  bannersSubscription: BehaviorSubject<InfoNotification[]> = new BehaviorSubject(null);
  notifications: InfoNotification[];
  notificationsSubscription: BehaviorSubject<InfoNotification[]> = new BehaviorSubject(null);
  defaultTimeout: number;
  timeouts: any[];

  constructor(private language: LanguageService) {
    this.banners = [];
    this.notifications = [];
    this.timeouts = [];
    // Timeout is set to be virtually infinite. This requires the info to be cleared on destroy in the component.
    this.defaultTimeout = 1000000;
  }

  /**
   * Removes the notification with the specified ID and emits the updated list to the subscribers.
   * Finds the timeout created for it and clears it
   * If no id is supplied clears the whole notifications and timeouts queue
   * @param id notification ID
   */
  clear(id?: number) {
    if (id) {
      this.banners = this.banners.filter(banner => banner.id !== id);
      this.bannersSubscription.next(this.banners);
      this.notifications = this.notifications.filter(notification => notification.id !== id);
      this.notificationsSubscription.next(this.notifications);
      const index = this.timeouts.findIndex(timeout => timeout.id === id);
      if (index > -1) clearTimeout(this.timeouts[index]);
      this.timeouts = this.timeouts.filter(timeout => timeout.id !== timeout.id);
    } else {
      this.banners = [];
      this.bannersSubscription.next(this.banners);
      this.notifications = [];
      this.notificationsSubscription.next(this.notifications);
      this.timeouts = [];
    }
  }

  /**
   * Creates an error notification based on the error object returned
   * from the api this push a notification based on the first key it finds
   * @param errorObj an object with keys to which fields that has an error on the request
   */
  errorKeys(errorObj: errorKeysInterface, disableTranslation?: boolean) {
    let objectKeys = Object.keys(errorObj);
    let firstKey = objectKeys && objectKeys[0];
    let title = errorObj[firstKey] && errorObj[firstKey][0];
    let translatedTitle = disableTranslation ? title : this.language.get('API_' + title);

    this.addNotification({ id: this.uniqueId(), title: translatedTitle, type: 'error' });
  }

  /**
   * Creates an error notification
   * @param title notification text
   */
  error(title: string, timeout: number = this.defaultTimeout, banner: Banner | false = false) {
    const notification = { id: this.uniqueId(), title: title, type: 'error' } as InfoNotification;
    banner
      ? this.addBanner({ ...notification, ...banner, type: 'danger' }, timeout)
      : this.addNotification(notification, timeout);
  }

  /**
   * Creates a info notification
   * @param title notification text
   */
  info(title: string, timeout: number = this.defaultTimeout, banner: Banner | false = false) {
    const notification = { id: this.uniqueId(), title: title, type: 'info' } as InfoNotification;
    banner
      ? this.addBanner({ ...notification, ...banner, type: 'info' }, timeout)
      : this.addNotification(notification, timeout);
  }

  /**
   * Creates a success notification
   * @param title notification text
   */
  success(title: string, timeout: number = this.defaultTimeout, banner: Banner | false = false) {
    const notification = { id: this.uniqueId(), title: title, type: 'success' } as InfoNotification;
    banner
      ? this.addBanner({ ...notification, ...banner, type: 'success' }, timeout)
      : this.addNotification(notification, timeout);
  }

  /**
   * Creates a warning notification
   * @param title notification text
   */
  warning(title: string, timeout: number = this.defaultTimeout, banner: Banner | false = false) {
    const notification = { id: this.uniqueId(), title: title, type: 'warning' } as InfoNotification;
    banner
      ? this.addBanner({ ...notification, ...banner, type: 'warning' }, timeout)
      : this.addNotification(notification, timeout);
  }

  /**
   * Adds a new banner, assigns it an ID and emits the updated list to the subscribers.
   * Creates a timeout to remove it after a pre-set delay
   * @param notification object containing the notification content and type
   */
  private addBanner(banner: InfoNotification, customTimeout?: number) {
    this.banners.push(banner);
    this.bannersSubscription.next(this.banners);

    const timeout = setTimeout(() => {
      const bannerIndex = this.banners.findIndex(msg => msg.id === banner.id);
      const timeoutIndex = this.timeouts.findIndex(timeout => timeout.id === banner.id);

      if (bannerIndex > -1) {
        this.banners.splice(bannerIndex, 1);
      }

      if (timeoutIndex > -1) {
        this.timeouts.splice(timeoutIndex, 1);
      }
    }, customTimeout || this.defaultTimeout);

    this.timeouts.push({
      id: banner.id,
      timeout: timeout,
    });
  }

  /**
   * Adds a new notification, assigns it an ID and emits the updated list to the subscribers.
   * Creates a timeout to remove it after a pre-set delay
   * @param notification object containing the notification content and type
   */
  private addNotification(notification: InfoNotification, customTimeout?: number) {
    this.notifications.push(notification);
    this.notificationsSubscription.next(this.notifications);

    const timeout = setTimeout(() => {
      const notificationIndex = this.notifications.findIndex(msg => msg.id === notification.id);
      const timeoutIndex = this.timeouts.findIndex(timeout => timeout.id === notification.id);

      if (notificationIndex > -1) {
        this.notifications.splice(notificationIndex, 1);
        this.notificationsSubscription.next(this.notifications);
      }

      if (timeoutIndex > -1) {
        this.timeouts.splice(timeoutIndex, 1);
      }
    }, customTimeout || this.defaultTimeout);

    this.timeouts.push({
      id: notification.id,
      timeout: timeout,
    });
  }

  /**
   * Generate a unique id for each item
   */
  private uniqueId() {
    return '_' + Math.random().toString(36).substr(2, 9);
  }
}
