import { Attribute, BelongsTo, JsonApiModel, JsonApiModelConfig } from 'cbr-jsonapi';
import { Product } from './product.model';

@JsonApiModelConfig({
  type: 'variants',
  apiVersion: 'v1',
})
export class Variant extends JsonApiModel {
  @Attribute({ serializedName: 'product_id' }) productId: number;
  @Attribute({ serializedName: 'cost_price' }) costPrice: number;
  @Attribute({ serializedName: 'retail_price' }) retailPrice: number;
  @Attribute({ serializedName: 'sale_price' }) salePrice: number;
  @Attribute({ serializedName: 'image_url' }) imageUrl: string;
  @Attribute({ serializedName: 'total_sold' }) totalSold: number;
  @Attribute({ serializedName: 'total_orders' }) totalOrders: number;
  @Attribute({ serializedName: 'age_range' }) ageRange: string;
  @Attribute() currency: string;
  @Attribute() url: string;
  @Attribute() sku: string;
  @Attribute() gtin: string;
  @Attribute() mpn: string;
  @Attribute() availability: string;
  @Attribute() condition: string;
  @Attribute() color: string;
  @Attribute() size: string;
  @Attribute() material: string;
  @Attribute() pattern: string;
  @Attribute() gender: string;
  @Attribute() multipack: number;
  @Attribute({ serializedName: 'created_at' }) createdAt: Date;
  @Attribute({ serializedName: 'updated_at' }) updatedAt: Date;
  @BelongsTo() product: Product;
}
