import { Component, OnInit } from '@angular/core';

declare function require(moduleName: string): any;
const { version: appVersion } = require('package.json');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor() {}

  async ngOnInit() {
    console.info(`App version: ${appVersion}`);
  }
}
