import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiUtilsService } from './api-utils.service';
import { ConsoleService } from '../services/console/console.service';

@Injectable()
export class ApiService {
  countries: any[];
  languages: any[];

  constructor(
    private http: HttpClient,
    private utils: ApiUtilsService,
    private console: ConsoleService,
  ) {}
  /**
   * @returns Promise containing an array of world countries
   */
  getCountries(): Promise<any> {
    if (!this.countries) {
      return this.utils
        .wrap(this.http.get, {
          url: this.utils.transformUrl('country'),
        })
        .then(response => {
          this.countries = response.countries;
          return this.countries;
        })
        .catch(error => {
          this.console.warn('Error while getting countries', error);
        });
    } else {
      return Promise.resolve(this.countries);
    }
  }
}
