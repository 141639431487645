import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { GetsTokens, Tokens } from '../domain/gets-tokens';

export interface ImpersonateUserResponse {
  data: {
    attributes: {
      user_id: string;
      token_type: string;
      expires_in: string;
      access_token: string;
      refresh_token: string;
    };
  };
}

@Injectable({ providedIn: 'root' })
export class HttpUserGatewayService implements GetsTokens {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.gateway}/v2`;
  }

  getByUserId(userId: string): Observable<Tokens> {
    const requestUrl = `${this.baseUrl}/impersonate-user`;

    const payload = {
      data: {
        attributes: {
          user_id: userId,
        },
      },
    };

    return this.http.post<ImpersonateUserResponse>(requestUrl, payload).pipe(
      map((res: ImpersonateUserResponse) => ({
        accessToken: res.data.attributes.access_token,
        refreshToken: res.data.attributes.refresh_token,
      })),
    );
  }
}
