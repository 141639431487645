import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountOwnershipsResponseModel, GoogleAccountsOwnership } from '@cbr/datastore';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleAccountsService {
  private googleAccountsOwnershipsMap: Map<string, Observable<GoogleAccountsOwnership>> = new Map();

  constructor(private http: HttpClient) {}

  getGoogleAccountOwnership(ownershipId: number): Observable<GoogleAccountsOwnership> {
    if (!ownershipId) {
      return of(null);
    }
    let cachedOwnership = this.googleAccountsOwnershipsMap.get(String(ownershipId));
    if (cachedOwnership) {
      return cachedOwnership;
    }
    cachedOwnership = this.http
      .get<AccountOwnershipsResponseModel>(
        `${environment.hub}/v1/google-accounts/ownerships/${ownershipId}?include=ads-account`,
      )
      .pipe(
        map((response: AccountOwnershipsResponseModel) => {
          const account = response.included.find(
            acc => acc.id === String(response.data.attributes.ads_account_id),
          );
          return {
            accountId: response.data.attributes.account_id,
            userId: response.data.attributes.user_id,
            id: response.data.id,
            adsAccount: {
              id: account?.id,
              name: account?.attributes.name,
              currency: account?.attributes.currency,
              timezone: account?.attributes.timezone,
              isLinked: account?.attributes.is_linked,
              isTester: account?.attributes.is_tester,
              isPartner: account?.attributes.is_partner,
              isManager: account?.attributes.is_manager,
              isSeamless: account?.attributes.is_seamless,
              mccAccountId: account?.attributes.mcc_account_id,
            },
          };
        }),
        shareReplay(1),
      );
    this.googleAccountsOwnershipsMap.set(String(ownershipId), cachedOwnership);
    return cachedOwnership;
  }
}
