import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

export interface SnackbarItem {
  id?: string;
  message: string;
  type: 'error' | 'warning' | 'success';
}

@Injectable({
  providedIn: 'root',
})
export class SnackbarState {
  private snackbarSubject: BehaviorSubject<SnackbarItem[]> = new BehaviorSubject([]);

  snackbar$ = this.snackbarSubject.asObservable();

  /**
   * Push a message to current snackbar observable
   * @param snackbarItem includes message and type(error, warning or success)
   * @param timeout timer on when to remove in ms, if 0 or less no timer is set
   */
  setMessage(snackbarItem: SnackbarItem, timeout: number = 3000) {
    // Concat array of messages
    this.snackbar$.pipe(take(1)).subscribe(val => {
      // Set unique id
      snackbarItem.id = this.uniqueId();

      // Add it to the snackbar
      this.snackbarSubject.next([...val, snackbarItem]);

      // If above 0 add timeout to remove itself
      if (timeout > 0) {
        setTimeout(() => this.removeMessage(snackbarItem.id), timeout);
      }
    });
  }

  /**
   * To remove a specific item from the snackbar
   * @param id the id of the item
   */
  removeMessage(id: string) {
    this.snackbar$.pipe(take(1)).subscribe(messageArr => {
      messageArr = messageArr.filter(snackbarItem => snackbarItem?.id !== id);

      this.snackbarSubject.next(messageArr);
    });
  }

  /**
   * Generate a unique id for each item
   */
  uniqueId() {
    return '_' + Math.random().toString(36).substr(2, 9);
  }
}
