import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorageService, LocalStorageInterface } from '@cbr/dark-matter';
import { DatastoreService, User } from '@cbr/datastore';

@Injectable({
  providedIn: 'root',
})
export class IsAdminGuard implements CanActivate {
  authStorage: LocalStorageInterface;
  customerStorage: LocalStorageInterface;

  constructor(
    localStorageService: LocalStorageService,
    private dataStoreService: DatastoreService,
    private router: Router,
  ) {
    this.authStorage = localStorageService.init('auth');
    this.customerStorage = localStorageService.init('customer');
  }

  canActivate(): Observable<boolean | UrlTree> {
    const authObj = this.authStorage.get();
    const customerObj = this.customerStorage.get();

    if (authObj && authObj.access_token && customerObj && customerObj.id) {
      return this.dataStoreService.findRecord(User, customerObj.id).pipe(
        map((user: User) => {
          if (user.group === 'admin') {
            return true;
          }
          return this.router.createUrlTree(['/logout']);
        }),
      );
    }

    return of(this.router.createUrlTree(['/logout']));
  }
}
