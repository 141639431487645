import { Component } from '@angular/core';
import { SnackbarState } from './snackbar.state';

@Component({
  selector: 'cbr-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent {
  snackbar$ = this.snackbarState.snackbar$;

  constructor(private snackbarState: SnackbarState) {}

  removeMessage(id: string) {
    this.snackbarState.removeMessage(id);
  }
}
