import { Attribute, JsonApiModel, JsonApiModelConfig, HasMany, BelongsTo } from 'cbr-jsonapi';

@JsonApiModelConfig({
  type: 'groups',
  modelEndpointUrl: 'keywords/groups',
  apiVersion: 'v1',
})
export class KeywordGroup extends JsonApiModel {
  @Attribute() text: string;
  @Attribute({ serializedName: 'landing_pages' }) landingPages: string[];
  @Attribute() site: number;
  @Attribute() custom: boolean;
  @Attribute({ serializedName: 'created_at' }) createdAt: string;
  @Attribute({ serializedName: 'updated_at' }) updatedAt: string;
  @HasMany() keywords: Keyword[];
}

@JsonApiModelConfig({
  type: 'keywords',
  modelEndpointUrl: 'keywords/keywords',
  apiVersion: 'v1',
})
export class Keyword extends JsonApiModel {
  @Attribute({ serializedName: 'average_cpc' }) averageCpc: number;
  @Attribute() group: number;
  @Attribute({ serializedName: 'search_volume' }) searchVolume: number;
  @Attribute() text: string;
  @Attribute({ serializedName: 'updated_at' }) updatedAt: string;
  @BelongsTo() keywordGroup: KeywordGroup;
}

@JsonApiModelConfig({
  type: 'fetch-keywords',
  modelEndpointUrl: 'keywords/fetch-keywords',
  apiVersion: 'v1',
})
export class FetchKeywordsJob extends JsonApiModel {
  @Attribute() site: number;
  @Attribute() locations: number[];
  @Attribute({ serializedName: 'company_name' }) companyName: string;
}

@JsonApiModelConfig({
  type: 'queue-job',
  modelEndpointUrl: 'keywords/fetch-keywords/queue-jobs',
  apiVersion: 'v1',
})
export class FetchKeywordsQueueJob extends FetchKeywordsJob {
  @Attribute() site: number;
  @Attribute() locations: number[];
  @Attribute({ serializedName: 'company_name' }) companyName: string;
  @Attribute() attempts: number;
  @Attribute() timeout: number;
  @Attribute() tries: number;
  @Attribute() failed: boolean;
  @Attribute({ serializedName: 'timeout_at' }) timeoutAt: Date;
  @Attribute({ serializedName: 'completed_at' }) completedAt: Date;
  @Attribute({ serializedName: 'created_at' }) createdAt: Date;
  @Attribute({ serializedName: 'updated_at' }) updatedAt: Date;
}
