import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface Tokens {
  accessToken: string;
  refreshToken: string;
}

export interface GetsTokens {
  getByUserId(userId: string): Observable<Tokens>;
}


export const GETS_TOKENS = new InjectionToken<GetsTokens>('GETS_TOKENS');
