import { Injectable, Inject } from '@angular/core';

import { StorageService } from '../../services/storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  current: string;
  dictionary: any;

  private defaultDictionary: any;
  private defaultLanguage: string;
  private initialized: boolean;
  private placeholder: string;
  private supportedLanguages: any[];

  constructor(private storage: StorageService, @Inject('Languages') private languages) {
    if (languages) {
      this.updateLanguageSettings(this.languages);
    }
    this.placeholder = '%';
    this.initialized = false;
  }

  /**
   * Gets the translation for a given key
   * @param key Key to be searched in the dictionary
   * @param words Eventual variables to be substituted into the string
   */
  get(key: string, words?: string | number | (number | string)[]): string {
    const value = this.getValue(key);

    return words !== undefined && words !== null ? this.replaceVariables(value, words) : value;
  }

  /**
   * Returns dictionary based on the one that is being passed
   * @param language  Language code e.g. 'en'
   * @returns Dictionary for the selected language
   */
  getDictionary(language: string): any {
    if (this.supports(language)) {
      return this.supportedLanguages.find(l => l.code === language).dictionary;
    }

    return this.supportedLanguages.find(l => l.code === 'en').dictionary;
  }

  /**
   * Reads the stored language code if the cookie exists (and checks if it's supported),
   * otherwise sets the cookie to default language and returns it
   * @returns Language code stored in cookies, or the default if none is set
   * */
  getStoredLanguage(): string {
    let language = this.storage.get('cbr_language');
    if (!language || !this.supports(language)) {
      language = this.defaultLanguage;
      this.storage.set('cbr_language', language);
    }
    return language;
  }

  /**
   * Finds value assigned to key in current dictionary, if not found then returns the english version
   * @param key key to be searched in the dictionary
   * @returns Value associated with key/default dictionary key/key
   * */
  getValue(key: string): string {
    const defaultValue = this.defaultDictionary?.[key];
    const value = this.dictionary?.[key];

    if (value) {
      return value.toString();
    } else if (defaultValue) {
      return defaultValue.toString();
    }

    return key;
  }

  /**
   * Needs to be called once the service needs to be setup at first use.
   * @param languages
   */
  init(languages: { code: string; dictionary: any; default: boolean }[]) {
    if (this.initialized) return;
    this.updateLanguageSettings(languages);
    this.set(this.getStoredLanguage());
  }

  /**
   * Language checks if language code is included in list of supported language
   * @param language  Language code e.g. 'en'
   * @returns If language is supported
   */
  supports(language: string): boolean {
    const supported = this.supportedLanguages.find(l => l.code === language);
    return !!supported;
  }

  /**
   * Replaces words into sentences when piped in as variables.
   * E.g. {{ "Hello %0" | language: world }} will substitute %0 with 'world'
   * @param sentence Base sentence that has been extracted from the dictionary
   * @param words Words that need to be inserted in the sentence
   * */
  replaceVariables(sentence: string, words: string | number | (number | string)[] = ''): string {
    let base: string = sentence;
    const values: string[] = [].concat(words);
    values.forEach((e, i) => {
      const regexp = new RegExp(this.placeholder.concat(<any>i), 'g');
      base = base.replace(regexp, e === undefined ? '' : e);
    });
    return base;
  }

  /**
   * Updates current language and dictionary to the one that is being passed
   * @param language Language code e.g. 'en'
   * */
  set(language: string) {
    if (this.supports(language)) {
      this.current = language;
      this.storage.set('cbr_language', this.current);
      this.dictionary = this.getDictionary(this.current);
    }
  }

  /**
   * Updates the languages supported, default language, and current language
   * @param languages: Keycode of the new language
   */
  updateLanguageSettings(languages: { code: string; dictionary: any; default: boolean }[]) {
    this.supportedLanguages = languages;
    const defaultLanguage = languages.find(language => language.default);
    this.set(defaultLanguage.code);
  }
}
