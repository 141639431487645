import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { HasData } from '../../../../core';
import { UUID } from '../../../../core';
import { DeletesUser } from '../domain/deletes-user';

export interface DeleteUserAttributes {
  userId: string;
}

@Injectable()
export class HttpUserDeleteService implements DeletesUser {
  constructor(private _http: HttpClient) {}

  delete(id: string): Observable<boolean> {
    const body: HasData<DeleteUserAttributes> = {
      data: {
        id: new UUID().value,
        type: 'delete-user',
        attributes: {
          userId: id,
        },
      },
    };

    return this._http.post(`${environment.gateway}/v1/users/delete-user`, body).pipe(
      map(() => true),
      catchError(() => of(false)),
    );
  }
}
